<template>
  <section class="edit-category">
    
    <div class="page-header mb-3">
      <h3 class="page-title" v-if="edit_Category.parent_id !== null">
        Edit Sub Category
      </h3>
      <h3 v-else class="page-title">Edit Category</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/view-category" class="text-primary">Category
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Category</li>
        </ol>
      </nav>
    </div>
    <div class="row" v-if="this.edit_Category">

      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group  mb-1">
              <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
              </label>
              <div class="form-group">
                <input type="text" v-model="edit_Category.name" id="name" name="name" class="form-control" :class="{
                  'is-invalid': submitted && $v.edit_Category.name.$error,
                }" />
                <div v-if="submitted && !$v.edit_Category.name.required" class="invalid-feedback">
                  Name is required
                </div>
              </div>
            </div>

            <div>
              <div class="form-group   mb-1">
                <label class=" fs-12  p-0 m-0  col-form-label" for="name">Image
                </label>
                <div class="form-group">
                  <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>
                  <chooseImage @imageId="setImageId"></chooseImage>
                  <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true"
                    v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                    <div class="dropzone-custom-content">
                      <div class="dropzone-custom-content">
                        <div v-if="bannerImg">
                          <div class="image-size-dropzone">
                            <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                          </div>
                          <div class="subtitle pt-1 pb-0">
                            <span> Change </span>
                            <span style="color: #eb2027"> Browse</span>
                          </div>
                        </div>
                        <div v-if="!bannerImg" class="pt-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                            fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-upload" color="">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                          <div class="subtitle pt-1 pb-5">
                            <span> Drop File or </span>
                            <span style="color: #eb2027"> Browse</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vue-dropzone>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-body">

            <div class="form-group  mb-1">
              <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

              <div class="form-group">
                <input type="text" class="form-control" v-model="edit_Category.sort" />
              </div>

            </div>
            <div>
              <div class="form-group   mb-1">
                <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                </label>
                <div class="form-group ">
                  <textarea class="form-control" rows="4" v-model="edit_Category.description"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group mb-0 ">
              <label for=""> Status </label>
              <div class="form-group  mb-0">
                <toggle-button v-model="edit_Category.active" :labels="{ checked: 'true', unchecked: 'false' }" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group float-right mb-0">
          <button class="btn btn btn-add btn-primary" v-on:click="category">
            Update
          </button>
        </div>
        <div class="form-group float-right pr-2 mb-0">
          <button class="btn btn btn-add btn-dark" v-on:click="deleteFile(edit_Category.id)">
            Remove
          </button>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { catalogApi } from "../../../api";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import axios from "axios";
import { required } from "vuelidate/lib/validators";

import chooseImage from "../../../components/chooseImage";
export default {
  name: "edit_category",
  props: {
    edit: {
      active: Boolean,
      sort: String,
      parent_id: Number,
      name: String,
      description: String,
      image_id: Number,
    },
  },
  components: {
    
    chooseImage,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      info: null,
      
      bannerImg: "",
      edit_Category: this.edit,
      submitted: false,
      imageApiURL,
    };
  },
  mounted() {
    this.categoryById();
  },
  validations: {
    edit_Category: {
      parent_id: {},
      name: { required },
    },
    submitted: false,
  },
  methods: {
    async categoryById() {
      
      const { data } = await catalogApi.categoryById(this.$route.params.id);
      data.forEach((value, index) => {
        this.edit_Category = value;
        if (this.edit_Category.image_id) {
          this.categoryImageById();
        }
        if (this.edit_Category.active === 1) {
          this.edit_Category.active = true;
        } else {
          this.edit_Category.active = false;
        }
      });
      
    },
    sendImages(file, xhr, formData) {
      let products = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", products);
    },

    success(file, response) {
      this.edit_Category.image_id = response.data.id;
      this.bannerImg = "";
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    setImageId(selectedId) {
      
      axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
        this.bannerImg = response.data.data[0];
        
      });
    },
    categoryImageById() {
      

      axios.get(`${mediaApi}/files/${this.edit_Category.image_id}`).then((response) => {
        this.bannerImg = response.data.data[0];
        
      });
    },
    async category() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.edit_Category.active === true) {
        this.edit_Category.active = 1;
      } else {
        this.edit_Category.active = 0;
      }
      for (var key in this.edit_Category) {
        if (this.edit_Category.hasOwnProperty(key)) {
          var val = this.edit_Category[key];
          console.log(val);
          if (val === null) {
            delete this.edit_Category[key];
          }
        }
      }
      if (this.bannerImg) {
        this.edit_Category.image_id = this.bannerImg.id;
      }
      this.edit_Category.label = this.edit_Category.name;
      
      const data = await catalogApi.updateCategory(this.$route.params.id, {
        ...this.edit_Category,
      });
      
      this.$router.push("/catalog/view-category");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },

    deleteFile(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = catalogApi.deleteCategory(id).then((response) => {
            
            if (response.data.code == 200) {
              this.$router.push("/catalog/view-category");
              if (result.value) {
                this.$swal("Deleted!", "Your file has been deleted.", "success");
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 4px !important;
}

.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}
</style>
